//FIX Flashing and wrong size font awesome icons on first load
require("@fortawesome/fontawesome-svg-core/styles.css");
const config = require("@fortawesome/fontawesome-svg-core");
config.autoAddCss = false;

//THIS IS REQUIRED TO REDIRECT / to /pl for default pl language
const React = require("react")
const {LANGUAGE_KEY} = require('gatsby-plugin-react-i18next/dist/types');
const {withPrefix} = require('gatsby');
const browserLang = require('browser-lang');

const STRIP_TRAILING_SLASH = true;

const removePathPrefix = (pathname, stripTrailingSlash) => {

    const pathPrefix = withPrefix('/');
    let result = pathname;

    if (pathname.startsWith(pathPrefix)) {
        result = pathname.replace(pathPrefix, '/');
    }

    if (stripTrailingSlash && result.endsWith('/')) {
        return result.slice(0, -1);
    }

    return result;
};

const isPathWithLangPrefix = (path, languages) => {
    for (const language of languages) {
        if (path.includes(`/${language}/`)) {
            return true
        }
    }
    return false;
}

exports.wrapPageElement = ({element, props}) => {

    if (!props) return;
    const {pageContext, location} = props;
    const {routed, language, languages, defaultLanguage} = pageContext.i18n;
    const isRedirect = !routed;

    if (isRedirect && !isPathWithLangPrefix(location.pathname, languages)) {
        const {search} = location;

        // Skip build, Browsers only
        if (typeof window !== 'undefined') {
            let detected =
                window.localStorage.getItem(LANGUAGE_KEY) ||
                browserLang({
                    languages,
                    fallback: language
                });

            if (!languages.includes(detected)) {
                detected = language;
            }

            window.localStorage.setItem(LANGUAGE_KEY, detected);

            const queryParams = search || '';
            const newUrl = withPrefix(
                `/${detected}${removePathPrefix(location.pathname, STRIP_TRAILING_SLASH)}${queryParams}${location.hash}`
            );
            window.___replace(newUrl);

            if (detected !== defaultLanguage) {
                return null;
            }
        }
    }
}
